// 编辑题库
<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源管理</a>
          <i>></i>
          <a href="javascript:;" @click="goHerf">培训题库</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">编辑题库</a>
          <p v-html="$xss(test)"></p>
        </span>
      </div>
      <div class="framePage-halfBody">
        <div class="framePage-article">
          <div class="ovy-a">
            <div>
              <div>
                <div class="title">
                  <h3>基本信息</h3>
                </div>
                <div class="titleMation">
                  <div style="margin-bottom: 10px">
                    <span v-if="bankNameShow">
                      <span style="min-width: 70px">题库名称:</span>
                      <span
                        @click="
                          () => {
                            bankNameShow = false;
                            $nextTick(() => {
                              $refs['input'].focus();
                            });
                          }
                        "
                        >{{ questionbankNameCopy }} <i class="el-icon-edit"></i
                      ></span>
                    </span>
                    <span v-else class="flexac">
                      <span style="min-width: 70px">题库名称:</span>
                      <el-input
                        v-model="questionbankNameCopy"
                        ref="input"
                        type="text"
                        size="mini"
                        placeholder="请输入题库名称"
                        clearable
                        maxlength="50"
                        show-word-limit
                        @keyup.enter.native="$event.target.blur"
                        @blur="changeBankName"
                      />
                    </span>
                  </div>
                  <div style="margin-bottom: 10px">
                    <span v-if="bankTrainShow">
                      <span style="min-width: 70px">培训类型:</span>
                      <span @click="bankTrainShow = false"
                        >{{ ruleForm.Trainingtype }}<i class="el-icon-edit"></i
                      ></span>
                    </span>
                    <span v-else class="flexac">
                      <span style="min-width: 70px">培训类型:</span>
                      <tree
                        ref="tree"
                        @eventBtn="childBack"
                        :ruleForm="ruleForm"
                        :parentCanecl="Canecl"
                        @clearParams="clearParams"
                        size="mini"
                        modal
                        type
                        typeStu
                      />
                    </span>
                  </div>
                  <div style="margin-bottom: 10px">
                    <span>
                      <span style="min-width: 70px">题目数量:</span>
                      <span>{{ totalNum }}道</span>
                    </span>
                  </div>
                  <div style="margin-bottom: 10px">
                    <span>
                      <span style="min-width: 70px">单选题量:</span>
                      <span>{{ choiceNum }}道</span>
                    </span>
                  </div>
                  <div style="margin-bottom: 10px">
                    <span>
                      <span style="min-width: 70px">多选题量:</span>
                      <span>{{ multiselectNum }}道</span>
                    </span>
                  </div>
                  <div style="margin-bottom: 10px">
                    <span>
                      <span style="min-width: 70px">判断题量:</span>
                      <span>{{ judgeNum }}道</span>
                    </span>
                  </div>
                  <div style="margin-bottom: 10px">
                    <span>
                      <span style="min-width: 70px">填空题量:</span>
                      <span>{{ completionNum }}道</span>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div class="title">
                  <h3>题库类型</h3>
                  <el-button
                    type="text"
                    class="title-button"
                    @click="clickTap(-1, '')"
                    >全部</el-button
                  >
                </div>
                <div class="searchBox">
                  <el-input
                    v-model="questionTitle"
                    type="text"
                    size="small"
                    placeholder="请输入题目内容"
                    clearable
                  >
                    <el-button slot="append" class="bgc-bv" @click="getData()"
                      >搜索</el-button
                    ></el-input
                  >
                </div>
                <div class="searchqusetionBox">
                  <div
                    v-for="(item, index) in items"
                    :key="index"
                    @click="clickTap(index, item.id)"
                  >
                    <span :class="currentClass(index)">
                      <img :src="item.img" />
                      {{ item.name }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="btnBox">
                <el-button type="primary" class="bgc-bv" @click="addquestion"
                  >新增试题</el-button
                >
                <el-button type="primary" class="bgc-bv" @click="BatchUpload"
                  >批量添加</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="framePage-body">
          <div class="ovy-a">
            <div class="searchbox">
              <div class="flexcb searchbox" style="justify-content: center">
                <h2 style="text-align: center">{{ questionbankName }}</h2>
              </div>
            </div>
            <div
              class="framePage-scroll flexdc"
              :style="!tableData.length ? 'height:93%' : ''"
            >
              <div class="ovy-a flex1" id="list-box">
                <div
                  class="list hoverShow"
                  v-for="(item, index) in tableData"
                  :key="index"
                  v-show="tableData.length > 0"
                >
                  <div class="list-item df">
                    <span
                      class="tixing"
                      :class="{
                        tixing1: item.qbQuestion.questionType == 0,
                        tixing2: item.qbQuestion.questionType == 1,
                        tixing3: item.qbQuestion.questionType == 2,
                        tixing4: item.qbQuestion.questionType == 3,
                      }"
                    >
                      {{
                        $setDictionary(
                          "QUESTIONTYPE",
                          item.qbQuestion.questionType
                        )
                      }}
                    </span>
                    <div class="subject flex1">
                      <div class="subject-title">
                        <span
                          v-html="$xss(item.qbQuestion.questionTitle)"
                          style="display: flex"
                          >{{ item.qbQuestion.questionTitle }}</span
                        >
                      </div>
                      <div
                        class="option"
                        v-if="item.qbQuestion.questionType == 0"
                      >
                        <span
                          v-for="(items, indexs) in item.qbQuestionOptions"
                          :key="indexs"
                          >{{ letterArr[indexs] }}.{{ items.optionContent }}
                          <el-image
                            v-if="items.optionSpecialContent"
                            class="qbimg"
                            :src="
                              items.optionSpecialContent ||
                                require('@/assets/develop.png')
                            "
                            fit="contain"
                          ></el-image>
                        </span>
                      </div>
                      <div
                        class="option"
                        v-if="item.qbQuestion.questionType == 1"
                      >
                        <span
                          v-for="(items, indexs) in item.qbQuestionOptions"
                          :key="indexs"
                          >{{ letterArr[indexs] }}.{{ items.optionContent }}
                          <el-image
                            v-if="items.optionSpecialContent"
                            class="qbimg"
                            :src="
                              items.optionSpecialContent ||
                                require('@/assets/develop.png')
                            "
                            fit="contain"
                          ></el-image
                        ></span>
                      </div>
                      <div class="analysis">
                        正确答案：<span
                          v-show="item.qbQuestionOptions.length != 0"
                          v-for="(items, indexs) in item.qbQuestionOptions"
                          :key="indexs"
                          >{{
                            items.optionIsCorrect ? letterArr[indexs] : ""
                          }}</span
                        >
                        <span v-show="item.qbQuestionOptions.length == 0">{{
                          item.qbQuestion.questionAnswer
                        }}</span>
                      </div>
                      <div style="display: flex">
                        <p>答案解析：</p>
                        <span
                          class="analysis img-boxs"
                          style="flex: 1"
                          v-html="$xss(item.qbQuestion.questionAnalysis)"
                          >{{ item.qbQuestion.questionAnalysis }}</span
                        >
                      </div>
                      <div
                        class="df showbtn"
                        style="float: right; padding: 8px 0"
                        v-show="stu != 'platform'"
                      >
                        <div style="white-space: nowrap">
                          <el-button
                            style="padding: 8px 20px"
                            v-show="
                              index !== 0 &&
                                TopictypeVal == '' &&
                                questionTitle == ''
                            "
                            @click="onSort(item.qbQuestion.questionId, 'top')"
                            >上移</el-button
                          >
                          <el-button
                            style="padding: 8px 20px"
                            v-show="
                              index !== tableData.length - 1 &&
                                TopictypeVal == '' &&
                                questionTitle == ''
                            "
                            @click="
                              onSort(item.qbQuestion.questionId, 'bottom')
                            "
                            >下移</el-button
                          >
                          <el-button
                            style="padding: 8px 20px"
                            @click="onEdit(item.qbQuestion.questionId)"
                            >编辑</el-button
                          >
                          <el-button
                            style="padding: 8px 20px"
                            @click="onDel(item.qbQuestion.questionId)"
                            >删除</el-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Empty2 slot="empty" v-show="!tableData.length" />
            </div>
          </div>
          <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
          />
        </div>
      </div>
    </div>
    <!-- 新增试题弹层start -->
    <questionPaperPop
      ref="questionPaperPop"
      @getnewdatap="getData"
      @getNum="getNum"
      @subShows="subShows"
    />
    <!-- 编辑试题弹层start -->
    <questionPaperEditPop
      ref="questionPaperEditPop"
      @getnewdatap="getData"
      @getNum="getNum"
      @subShows="subShows"
    />
    <!-- 批量上传start -->
    <questionPaper ref="questionPaper" @eventBus="paperBack" />
    <!-- end -->
  </div>
</template>
<script>
import Empty2 from "@/components/Empty2.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";

import questionPaperPop from "@/views/resourceManagement/questionPaperPop.vue";
import questionPaperEditPop from "@/views/resourceManagement/questionPaperEditPop.vue";
import questionPaper from "@/views/resourceManagement/questionbankUpload.vue";
// import questionbankInformation from "@/views/Questionbank/questionbankInformation.vue";
export default {
  name: "questionbankAdd",
  components: {
    Empty2,
    PageNum,
    questionPaperPop,
    questionPaperEditPop,
    questionPaper,
    tree,
    // questionbankInformation,
  },
  mixins: [List],
  data() {
    return {
      test: `<a onclick='alert("xss攻击")'>链接</a>`,
      letterArr: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "X",
        "Y",
        "Z",
      ],
      currentNumber: -1,
      categoryName: "",
      items: [
        {
          img: require("@/assets/radio.png"),
          name: "单选题",
          id: "0",
        },
        {
          img: require("@/assets/check.png"),
          name: "多选题",
          id: "1",
        },
        {
          img: require("@/assets/judge.png"),
          name: "判断题",
          id: "2",
        },
        {
          img: require("@/assets/fill.png"),
          name: "填空题",
          id: "3",
        },
      ],
      TopictypeVal: "",
      questionTitle: "",
      stu: "", // 状态
      ///
      ruleForm: {
        Trainingtype: "",
      },
      TrainingtypeCopy: "",
      questionbankNameCopy: "",
      questionbankName: "",
      bankNameShow: true,
      bankTrainShow: true,
      trainTypeId: "",
      industryId: "",
      occupationId: "",
      postId: "",
      trainLevelId: "",
      totalNum: 0,
      choiceNum: 0,
      multiselectNum: 0,
      judgeNum: 0,
      completionNum: 0,
    };
  },
  created: function() {
    this.questionBankId = this.$route.query.questionBankId;
  this.getNum()
   
  },
  methods: {
    getNum() {
       this.$post("/special/exam/question/bank/query", {
      questionBankId: this.questionBankId,
    }).then((res) => {
      ;
      this.questionbankName = res.data.questionBankName;
      this.totalNum = res.data.totalNum || 0;
      this.choiceNum = res.data.choiceNum || 0;
      this.completionNum = res.data.completionNum || 0;
      this.judgeNum = res.data.judgeNum || 0;
      this.multiselectNum = res.data.multiselectNum || 0;

      this.questionbankNameCopy = res.data.questionBankName;
      this.questionBankId = res.data.questionBankId;
      this.ruleForm.Trainingtype = res.data.categoryName;
      this.TrainingtypeCopy = res.data.categoryName;

      this.trainTypeId = res.data.trainTypeId || "";
      this.industryId = res.data.industryId || "";
      this.occupationId = res.data.occupationId || "";
      this.trainLevelId = res.data.trainLevelId || "";
      this.postId = res.data.postId || "";
      this.getData();
    });
    },
    // 培训类型回调
    childBack(params1) {
      this.params = { ...params1 };
      // this.paramsCopy = { ...params1 };
      let params = {
        questionBankId: this.questionBankId,
        questionBankName: this.questionbankName,
      };
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      if (
        !params.industryId &&
        !params.postId &&
        !params.occupationId &&
        !params.trainLevelId &&
        !params.trainTypeId
      ) {
        this.$message({
          type: "warning",
          message: "培训类型不能为空",
        });
        this.ruleForm.Trainingtype = this.TrainingtypeCopy;
        this.bankTrainShow = true;
      } else {
        this.bankTrainShow = true;
        this.$post("/special/exam/question/bank/updateById", params).then(
          (res) => {
            ;
            if (res.status == "0") {
              this.$message({
                type: "success",
                message: "培训类型修改成功",
              });
              this.TrainingtypeCopy = this.ruleForm.Trainingtype;
              this.trainLevelId = this.params.levelFormId;
              this.trainTypeId = this.params.trainFormId;
              this.occupationId = this.params.occFormId;
              this.postId = this.params.postFormId;
              this.industryId = this.params.industryFormId;
            }
          }
        );
      }
    },
    clearParams() {
      this.params = {};
    },
    Canecl() {
      this.bankTrainShow = true;
      this.ruleForm.Trainingtype = this.TrainingtypeCopy;
    },
    // 修改题库名称
    changeBankName() {
      if (this.questionbankNameCopy.trim() == "") {
        this.$message({
          type: "warning",
          message: "题库名称不能为空",
        });
      } else {
        this.bankNameShow = true;
        let params = {
          questionBankId: this.questionBankId,
          questionBankName: this.questionbankNameCopy,
          industryId: this.industryId || "",
          occupationId: this.occupationId || "",
          postId: this.postId || "",
          trainLevelId: this.trainLevelId || "",
          trainTypeId: this.trainTypeId || "",
        };
        this.$post("/special/exam/question/bank/updateById", params).then(
          (res) => {
            ;
            if (res.status == "0") {
              this.questionbankName = this.questionbankNameCopy;
              this.$message({
                type: "success",
                message: "题库名称修改成功",
              });
            }
          }
        );
      }
    },
    /* 获取数据start */
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        questionBankId: this.$route.query.questionBankId || this.questionBankId,
      };
      if (this.TopictypeVal) {
        params.questionType = this.TopictypeVal;
      }
      if (this.questionTitle) {
        params.questionTitle = this.questionTitle;
      }
      this.doFetch({
        url: "/special/exam/question/pageList",
        params,
        pageNum,
      });
    },
    /* end */
    /* 题库类型切换数据 start*/
    clickTap(index, id) {
      this.currentNumber = index;
      this.TopictypeVal = id;
      this.questionTitle = "";
      this.getData();
    },
    currentClass(index) {
      return [this.currentNumber == index ? "addclass" : ""];
    },
    /* end */
    /* 新增试题start 编辑试题 */
    onEdit(questionId) {
      this.$refs.questionPaperEditPop.showPopUp(questionId);
    },
    addquestion() {
      if (this.questionBankId) {
        this.$refs.questionPaperPop.showPopUp(this.questionBankId);
      } else {
        this.$confirm("请先设置题库的基本信息!", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "bgc-bv",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$refs.questionbankInformation.showPopUp();
          })
          .catch(() => {
            return;
          });
      }
    },
    /* end */
    /* 上移&&下移start */
    onSort(questionId, stu) {
      this.$post(
        stu == "top"
          ? "/special/exam/question/moveUpQuestion"
          : "/special/exam/question/moveDownQuestion",
        { questionId }
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    /* end */
    /* 试题删除start */
    onDel(questionId) {
      const that = this;
      that
        .$confirm(
          "你确定要从" + that.questionbankName + "中删除该试题吗",
          "删除",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            confirmButtonClass: "confirmButtonClass",
            type: "warning",
          }
        )
        .then(() => {
          that
            .$post("/special/exam/question/delete", { questionId })
            .then((ret) => {
              if (ret.status == 0) {
                that.subShow = true;
                that.getData();
                that.getNum()
              } else {
                that.$message({
                  type: "error",
                  message: ret.message,
                });
                
              }
            });
        })
        .catch(() => {
          return;
        });
    },
    /* end */
    /* 批量上传start */
    BatchUpload() {
      if (this.questionBankId) {
        this.$refs.questionPaper.showPopUp(this.questionBankId);
      } else {
        this.$confirm("请先设置题库的基本信息!", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "bgc-bv",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$refs.questionbankInformation.showPopUp();
          })
          .catch(() => {
            return;
          });
      }
    },
    paperBack() {
      this.getData();
      this.getNum()
    },
    /* end */
    /* 设置题库基本信息start */
    setInformation() {
      this.$refs.questionbankInformation.showPopUp(this.questionBankId);
    },
    // questiionbankBacks(data) {
    //   sessionStorage.setItem("questionJson", JSON.stringify(data));
    //   // this.JsonRoute.questionBankName = data.questionBankName
    //   this.questionbankName = data.questionBankName;
    //   this.categoryName = data.categoryName;
    //   this.questionBankId = data.questionBankId;
    // },
    /* end */
    /* 返回 */
    goHerf() {
      this.$router.push({
        path: "/web/platformOfquestionBankList",
        query: {
          refresh: true,
        },
      });
    },
  },
};
</script>
<style lang="less">
.el-upload-list__item {
  /deep/img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto !important;
    height: auto !important;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    z-index: 1;
  }
}
.h3 {
  padding: 0.75rem 0;
}
</style>
<style lang="less" scope>
.framePage-halfBody {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.framePage-halfBody .framePage-article {
  background-color: #fff;
  margin-right: 0.675rem;
  height: 100%;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
  width: 20%;
  min-width: 20rem;
  .title {
    display: flex;
    justify-content: space-between;
    position: relative;
    h3 {
      font-size: 0.85rem;
    }
    h3:before {
      content: "";
      border-left: 4px solid #4574f9;
      width: 4px;
      height: 16px;
      position: absolute;
      left: -14px;
    }
    .title-button {
      padding: 0;
      font-size: 0.8rem;
    }
  }
  .searchBox {
    display: flex;
    margin: 15px 0;
  }
  .searchqusetionBox {
    display: flex;
    flex-wrap: wrap;
    div {
      width: 50%;
      display: flex;
      margin: 15px 0;
      span {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }
      .addclass {
        color: #4574f9;
      }
    }
  }
  .titleMation {
    // display: flex;
    // flex-direction: column;
    padding: 0.9rem 0 1.8rem 0;
    // span:nth-child(2) {
    //   padding: 0.5rem 0;
    // }
  }
  .btnBox {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}
.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        display: flex;
        justify-content: space-between;
        .number {
          padding-left: 1rem;
        }
        img {
          width: 100px;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: auto;
          display: flex;
          align-items: center;
          .qbimg {
            width: 5rem;
          }
        }
      }
    }
  }
}
.img-boxs {
  img {
    width: 100px;
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.informationmBox {
  display: flex;
  justify-content: space-between;
}
.el-divider--horizontal /deep/ {
  margin: 12px 0;
}
.el-cascader /deep/ {
  width: 100%;
}
.el-form-item__content /deep/ .el-upload {
  width: 100px;
  height: 148px !important;
}
.bannerBox .el-form-item__content /deep/ .df {
  display: flex;
}
.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
.el-upload-list--picture-card /deep/.el-upload-list__item-actions {
  z-index: 9999;
}
.showbtn {
  display: none;
}
.hoverShow :hover {
  .showbtn {
    display: block;
  }
}
</style>
