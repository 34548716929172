<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="5%"
    width="50%"
    title="批量添加"
    :center="true"
    :before-close="doClose"
  >
    <div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="从Excel上传" name="first">
        </el-tab-pane>
        <!-- <el-tab-pane label="从题库添加" name="second">
        </el-tab-pane> -->
      </el-tabs>
    </div>
    <section>
      <div class="ovy-a">
        <div class="questionbankExcelBox">
          <div class="questionbank_divBox">
            <div class="questionbank_div">
              <div class="questionbank_template">
                <div>
                  <h4>第一步：</h4>
                  <p>将您的文档按照模版中的格式调整好</p>
                  <el-button
                    size="small"
                    class="questionbank_button"
                    @click="downloadExcel"
                    >下载Excel模版</el-button
                  >
                </div>
                <img src="@/assets/template.png" alt />
              </div>
            </div>
          </div>
          <div class="questionbank_divBox">
            <div class="questionbank_div">
              <div class="questionbank_template">
                <div>
                  <h4>第二步：</h4>
                  <p>调整好格式后，就开始上传文档吧~</p>
                  <el-upload
                    v-model="ruleForm.fileName"
                    v-if="!ruleForm.fileName"
                    class="upload-demo upload-btn questionbank_button"
                    :action="actionUrl"
                    :on-error="handleError"
                    :on-success="handleSuccess"
                    :on-change="uploadChange"
                    :show-file-list="false"
                    :auto-upload="false"
                  >
                    <el-button size="small" class="bgc-bv"
                      >上传Excel文档</el-button
                    >
                  </el-upload>
                  <span v-else style="color: #f46173; margin-top: 20px">
                    {{ ruleForm.fileName }}
                    <a @click="reomveExl" style="color: #5bb5ff">删除</a>
                  </span>
                </div>
                <img src="@/assets/questionbank.png" alt />
              </div>
            </div>
          </div>
        </div>
        <div class="FormatBox">
          <p class="p_red">温馨提示：</p>
          <p class="p_red">
            为了避免题库里试题重复，请您每次批量添加时确认excel表里是否有重复的试题。
          </p>
          <div class="FormatDescriptionBox">
            <p>格式说明：</p>
            <p>1.只能上传.XLS和.XLSX格式的单个文件，且文件大小不超过1MB。</p>
            <p>2.单选选项之前不用填写A、B、C、D、E、F。</p>
            <p>3.按照顺序填写，中间出现空行无法导入。</p>
            <p>4.答案只能填写T或F，其中T代表正确，F代表错误。</p>
            <p>5.填空题目，填空答案和答案解析不能为空。</p>
            <!-- <p>6.答案解析中，需要提醒的文字需要使用{{ spanTitle }}标签标记。</p> -->
          </div>
        </div>
        <div class="flexcc">
          <el-button class="bgc-bv" @click="doClose">取消</el-button>
          <el-button class="bgc-bv" @click="formSave()" :disabled="disabled"
            >保存</el-button
          >
        </div>
      </div>
    </section>
  </el-dialog>
</template>
<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import Form from "@/mixins/Form";
export default {
  name: "questionbankExcel",
  mixins: [UploadPic, DatePicker, Form],
  props: ["questionBankId"],
  components: {},

  data() {
    return {
      dialogVisible: false,
      activeName: "first",
      stu: false,
      actionUrl: "",
      spanTitle: '<span style="color:red"></span>',
      ruleForm: {
        excelUrl: "",
        fileName: "",
      },
      props: {
        value: "id",
        label: "label",
      },
      params: {},
      //是否上传文件
      updateFile: false,

      checkShow: false,
      disabled: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    // 保存
    formSave() {
      // console.log(this.questionBankId);
      this.disabled = true;
      this.doAjax();
    },
    /* 下载模版 */
    downloadExcel() {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/questionBank_temp.xls";
      link.setAttribute("download", "questionBank_temp.xls");
      document.body.appendChild(link);
      link.click();
    },
    doAjax() {
      let params = {
        excelUrl: this.ruleForm.excelUrl,
        questionBankId: this.questionBankId,
      };

      if (this.ruleForm.excelUrl == "") {
        this.$message.warning("请先上传Excel文档");
        return false;
      }
      this.$post("/special/exam/question/importQuestionBank", params)
        .then((re) => {
          // this.$emit("eventBusOk", re.data);
          this.parentPsBack(re.data);
          if (re.status == "-1") {
            this.$message({
              type: "error",
              message: re.message,
            });
          } else if (re.status == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
          }
          this.reset();
        })
        .catch((err) => {
          console.log(err);
          if (err.data.status == "-1") {
            this.ruleForm = {
              excelUrl: "",
              fileName: "",
            };
            this.disabled = false;
          }
        });
    },
    /**
     *
     */
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      this.ruleForm.fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.ruleForm.excelUrl = result.data.fileKey;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    reomveExl() {
      this.ruleForm.fileName = "";
      this.ruleForm.excelUrl = "";
      this.actionUrl = "";
      this.$forceUpdate();
    },
    reset() {
      this.doClose();
      this.ruleForm = {
        excelUrl: "",
        fileName: "",
      };
      this.disabled = false;
    },
    // 给父组件传参
    parentPsBack(id) {
      this.$emit("eventBus", id);
      this.doClose();
    },
    showPopUp(questionBankId) {
      this.dialogVisible = true;
      this.questionBankId = questionBankId;
    },
    doClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="less">
.questionbankExcelBox {
  display: flex;
  width: 100%;
  height: 10rem;
  .questionbank_divBox {
    width: 50%;
    padding: 0.5rem;
    box-sizing: border-box;
    .questionbank_div {
      padding: 0.5rem;
      border: 1px solid #f9f9f9;
      height: 100%;
      background: #fafafa;
      display: flex;
      .questionbank_template {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex: 1;
        .upload-btn {
          height: 32px;
          .el-upload {
            height: 32px !important;
            border: none !important;
          }
        }
        .questionbank_button {
          margin-top: 1rem;
        }
      }
    }
  }
}
.FormatBox {
  padding: 1.75rem 1rem;
  .p_red {
    color: red;
  }
  .FormatDescriptionBox {
    padding-top: 0.75rem;
    line-height: 1rem;
  }
}
</style>

